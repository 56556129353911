import React from "react";
import logo from "../../assets/kda_logo.png";
// import Countdown from "../../components/public/Countdown";
import {EventState_Consumer} from "../../contexts/event_state";

class HomePage extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot){
    if (this.props.eventState.isEnd){
      this.props.history.push("/finish");
      return;
    }
    if (this.props.eventState.isLive){
      this.props.history.push("/live");
      return;
    }
  }

  render(){
    return (
      <div className="App countdown-screen">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo"/>
        </div>
        <h3 className="title">Get ready for the <strong>KDA 2023 Full Length Play Festival</strong> Awards Evening</h3>
        {/*<br />*/}
        <h1>Soon... 🙂</h1>
        {/*<Countdown date={`2022-06-18T19:00:00`}/>*/}

        <a href={"https://kentdrama.org.uk/h_festivals.htm"} target={"_new"}>View 2022 Festival</a>
      </div>
    );
  }
}

export default EventState_Consumer(HomePage, eventState => {
  return {
    eventState
  }
})