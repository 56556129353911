import React from "react";
import EventState from "./_context";
import {socketConnect} from "socket.io-react";

class EventState_Provider extends React.Component {
    state = {
        eventState: {
            isLive: false,
            isEnd: false,
            videoLink: "https://www.youtube.com/watch?v=37OPLrHUAq0",
            showZoomLink: false,
            chatEnabled: true,
            onlineCount: 0
        },
    };
    componentDidMount() {
        this.socket_registerEvents();
        this.socket_onConnect();
    }
    componentWillUnmount(){
        this.socket_deregisterEvents();
    }
    _refreshSettings = () => {
        this.props.socket.emit("event:state", {});
    };
    socket_onConnect(){
        this._refreshSettings();
    }
    socket_registerEvents(){
        this.props.socket.on("event:state", this.event_onEventState);
        this.props.socket.once("authenticated", () => {
            this.socket_deregisterEvents();
            this.socket_registerEvents();
            this.socket_onConnect();
        });
    }
    socket_deregisterEvents(){
        if(this.props.socket){
            this.props.socket.removeListener("event:state", this.event_onEventState);
        }
    }

    event_onEventState = data => {
        this.setState({
            eventState: data
        })
    }

    render() {
        return (
            <EventState.Provider
                value={{
                    _refresh: this._refreshSettings,
                    ...this.state.eventState,
                }}
            >
                {this.props.children}
            </EventState.Provider>
        );
    }
}
export default socketConnect(EventState_Provider);