import React from "react";
import {Route, Switch} from "react-router-dom";
import {SemanticToastContainer} from "react-semantic-toasts";
import FrontendUpdateChecker from "./components/common/FrontendUpdateChecker";
import PublicIndex from "./pages/_public";

function App() {
    return (
        <React.Fragment>
            <SemanticToastContainer position="top-right"/>
            <FrontendUpdateChecker />
            <Switch>
                <Route path="/" component={PublicIndex} />
            </Switch>
        </React.Fragment>
    );
}

export default App;
