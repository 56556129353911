import React from "react";
import ReactGA from 'react-ga';
import AppConfig from "../../_appConfig"

export default class GoogleAnalytics extends React.Component {
    heartbeat = null;
    componentDidMount() {
        if(AppConfig.isDebug){ return; }

        ReactGA.initialize(this.props.trackingID, { debug: AppConfig.isDebug });
        this.sendPageView(this.props.location);
        this.props.history.listen(this.sendPageView);

        this.heartbeat = setInterval(() => this.sendHeartbeat(), 60 * 1000);
    }
    componentWillUnmount() {
        if(this.heartbeat !== null){
            clearInterval(this.heartbeat);
            this.heartbeat = null;
        }
    }
    sendPageView(location){
        const page = location.pathname;
        ReactGA.pageview(page);
        console.info(`[react-router-ga] Page view: ${page}`);
    }

    sendHeartbeat(){
        ReactGA.event({
            category: "background_events",
            action: "Heartbeat",
            nonInteraction: true
        });
    }

    render(){
        return this.props.children;
    }
}