import {Route, Switch} from "react-router-dom";
import GoogleAnalytics from "../../components/public/GoogleAnalytics";
// import SocketWrapper from "../../components/public/PublicSocketIo";
// import {EventState_Provider} from "../../contexts/event_state";
import HomePage from "./HomePage";
// import RadioFestival from "./RadioFestival";
// import PhotoSubmissions from "./PhotoSubmissions"
// import LivePage from "./Live";
// import EndPage from "./EndPage";

function Index(props){
  return (
    <GoogleAnalytics trackingID={"UA-35885275-2"} location={props.location} history={props.history}>
      {/*<SocketWrapper>*/}
      {/*<EventState_Provider>*/}
      <Switch>
        {/*<Route exact path={"/finish"} component={EndPage} />*/}
        {/*<Route exact path={"/live-123"} component={LivePage}/>*/}
        {/*<Route exact path={"/photo_submission"} component={PhotoSubmissions}/>*/}
        {/*<Route path={"/"} component={EndPage}/>*/}
        {/*<Route exact path={"/radio_festival"} component={RadioFestival} />*/}
        <Route path={"/"} component={HomePage} />
      </Switch>
      {/*</EventState_Provider>*/}
      {/*</SocketWrapper>*/}
    </GoogleAnalytics>
  );
}
export default Index;