import React from "react";
import EventState from "./_context";

const joinProps = (a, b) => {
    return {
        ...a,
        ...b,
    };
};
const Consumer = (Compo, mapContext) => {
  return (props) => {
    return <EventState.Consumer>{(context) => <Compo {...joinProps(props, mapContext(context))} />}</EventState.Consumer>;
  };
};
export default Consumer;
